import React, { Component } from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import {
  Button,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Row,
  Container,
  Modal
} from "reactstrap";
import classNames from "classnames";
import _ from "lodash";
import Page from "./Page.js";
import Login from "./Login.js";

class Site extends Component {
  constructor(props) {
    super(props);

    this.state = {
      detailItem: null,
      activeTab: 0,
      accountDropdownOpen: false,
      loginWindowOpen: false,
      loginError: null,
      username: "",
      password: "",
      isProposals: false,
    };
    this.toggleAccountDropdown = this.toggleAccountDropdown.bind(this);
    this.toggleLoginModal = this.toggleLoginModal.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.setPageDetail = this.setPageDetail.bind(this);
    this.setPageProposals = this.setPageProposals.bind(this);
    this.formManager = this.formManager.bind(this);
    this.onLoginSubmit = this.onLoginSubmit.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      _.get(prevProps, "signInFetch.pending") &&
      !_.get(this.props, "signInFetch.pending")
    ) {
      this.setState({
        loginError: _.get(this.props, "signInFetch.reason.cause.errorMessage")
      });
    }
  }

  toggleAccountDropdown() {
    this.setState({ accountDropdownOpen: !this.state.accountDropdownOpen });
  }

  toggleLoginModal() {
    this.setState({ loginWindowOpen: !this.state.loginWindowOpen });
  }

  setActiveTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  setPageDetail(item) {
    this.setState({ detailItem: item });
  }

  setPageProposals(state = true) {
    this.setState({ isProposals: state });
  }

  getPages() {
    //const data = this.props.data;
    // const activeTab = this.state.activeTab;
    // const setActiveTab = this.setActiveTab;

    return (
      <Container className="children" fluid={true}>
        <Page
          sfCreateOpportunity ={this.props.sfCreateOpportunity}
          getSfData = {this.props.getSfData}
          isSalesforce={this.props.isSalesforce}
          isLoggedIn={this.props.isLoggedIn}
          isLoading={this.props.isLoading}
          isDimmed={this.props.isDimmed}
          productRowsFetch={this.props.productRowsFetch}
          productRowsLazyFetch={this.props.productRowsLazyFetch}
          log_transaction={this.props.log_transaction}
          data={this.props.data}
          detailItem={this.state.detailItem}
          setPageDetail={this.setPageDetail}
          isProposals={this.state.isProposals}
          setPageProposals={this.setPageProposals}
          proposalLazyCreate={this.props.proposalLazyCreate}
          proposalCreate={this.props.proposalCreate}
          proposalLazyUpdate={this.props.proposalLazyUpdate}
          proposalUpdate={this.props.proposalUpdate}
          proposalLazyDelete={this.props.proposalLazyDelete}
          proposalDelete={this.props.proposalDelete}
          proposalsLazyFetch={this.props.proposalsLazyFetch}
          proposalsFetch={this.props.proposalsFetch}
          proposalQuoteLazyCreate={this.props.proposalQuoteLazyCreate}
          proposalQuoteCreate={this.props.proposalQuoteCreate}
          proposalQuotesFetch={this.props.proposalQuotesFetch}
          user={this.props.user}
        />
        <footer>
          <div>
            <div>
              <div className="poweredBy">
                Powered by{" "}
                <a
                    href="https://www.workrails.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  WorkRails
                </a>
              </div>
            </div>
          </div>
        </footer>
      </Container>
    );
    /*switch(data.contents.display_as){
      case "tabs":
        return(
          <div className="body">
            <div className="tabs">
              <Nav tabs>
                {data.contents.pages.map((data,index) => {
                  return <NavItem key={index}><NavLink className={classNames({ active: activeTab === index})} onClick={() => {setActiveTab(index);}}>{data.title}</NavLink></NavItem>
                })}
              </Nav>
            </div>
            <TabContent className="pages" activeTab={this.state.activeTab}>
              {data.contents.pages.map((data, index) => {
                return <TabPane tabId={index} key={data.id} className={data.type + "-panel"} ><Page isLoggedIn={this.props.isLoggedIn} productRowsFetch={this.props.productRowsFetch} productRowsLazyFetch={this.props.productRowsLazyFetch} log_transaction={this.props.log_transaction} data={data} detailItem={this.state.detailItem} setPageDetail={this.setPageDetail} /></TabPane>
              })}
            </TabContent>
          </div>
        );
      case "sections":
        return(
          <div className="pages">
            {data.contents.pages.map((data, index) => {
              return <div key={data.id} index={index} className="page"><Page data={data} detailItem={this.state.detailItem} setPageDetail={this.setPageDetail} /></div>
            })}
          </div>
        );
      case "cards":
        return(
          <div className="pages">
            {data.contents.pages.map((data, index) => {
              return <div key={data.id} data={data} index={index} />
            })}
          </div>
        );
      case "accordian":
        break;
      default:
        return(
          <Container className="children" fluid={true}>
            {data.contents.pages.map((data, index) =>{
              return <Page isLoggedIn={this.props.isLoggedIn} productRowsFetch={this.props.productRowsFetch} productRowsLazyFetch={this.props.productRowsLazyFetch} log_transaction={this.props.log_transaction} key={data.id} data={data} index={index} detailItem={this.state.detailItem} setPageDetail={this.setPageDetail} />
            })}
          </Container>
        );
    }*/
  }

  formManager(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  logout() {
    this.setPageProposals(false);
    this.props.clearUser();
  }

  onLoginSubmit() {
    if (this.state.username === "") {
      this.setState({ loginError: "Email Required" });
      return;
    } else if (this.state.password === "") {
      this.setState({ loginError: "Password Required" });
      return;
    }
    this.props.lazySignIn({
      email: this.state.username,
      password: this.state.password
    });
  }

  onForgotPWSubmit(){
    if(this.state.email.indexOf('@') >= this.state.email.indexOf('.') && this.state.email.indexOf('.') !== -1){
      this.setState({loginError: "Please enter a valid email address"});
      return;
    }
    this.setState({loginError: null});
    this.props.sendPasswordReset({ data: { email: this.state.email } });
  }

  getNavItems(){
    if(!this.props.is_public && !this.props.isLoggedIn()){
      return null;
    }
    return this.props.isLoggedIn() ?
        <Nav className="ml-auto" navbar>
          <NavItem><Link to="/proposals" onClick={this.setPageProposals} className={classNames({ active: this.state.isProposals })}>My SOWs</Link></NavItem>
          <NavItem><Button onClick={this.logout}>Logout</Button></NavItem>
        </Nav> :
        <Button onClick={this.toggleLoginModal}>Login</Button>;
  }

  render() {
    return (
        <BrowserRouter>
          <div>
            <Route
                exact
                path="*"
                render={() => {
                  return (
                      <div
                          className={classNames(
                              "site",
                              { list: !this.state.detailItem },
                              { detail: this.state.detailItem },
                              { proposal: this.state.isProposals },
                              { private: (!this.props.is_public && !this.props.isLoggedIn()) }
                          )}
                      >
                        <Modal isOpen={this.state.loginWindowOpen} toggle={this.toggleLoginModal}>
                          <Login loginError={this.state.loginError} onForgotPWSubmit={this.onForgotPWSubmit} loginSuccess={this.state.loginSuccess} formManager={this.formManager} onLoginSubmit={this.onLoginSubmit}/>
                        </Modal>
                        <Navbar className='u-margin-0' light expand="xl">
                          <Container className='u-padding-0'>
                            <Row>
                              <NavbarBrand>
                                <Link to="/" onClick={() => { this.setPageProposals(false)}}>
                                  <img
                                      src={this.props.data.company.image}
                                      className="logo"
                                      alt={this.props.data.company.title}
                                  />
                                </Link>
                              </NavbarBrand>
                              {this.getNavItems()}
                            </Row>
                          </Container>
                          {this.props.is_public === "true" && (
                              <Nav className="ml-auto" navbar>
                                <Button>Login</Button>
                              </Nav>
                          )}
                        </Navbar>
                        {(!this.props.data.is_public && !this.props.isLoggedIn()) ?
                            <Row className="body private">
                              <div className="login col-lg-4 col-md-6 col-sm-8 mx-auto">
                                <Login loginError={this.state.loginError} loginSuccess={this.state.loginSuccess} formManager={this.formManager} onForgotPWSubmit={this.onForgotPWSubmit} onLoginSubmit={this.onLoginSubmit}/>
                              </div>
                            </Row>
                            : this.getPages()}

                      </div>
                  );
                }}
            />
          </div>
        </BrowserRouter>
    );
  }
}

export default Site;
