import React, { Component } from "react";
import Flatpickr from 'react-flatpickr';
import { Link } from "react-router-dom";
import _ from "lodash";
import {Modal, ModalHeader, ModalBody, ModalFooter, Alert} from 'reactstrap';
import sfLogo from '../../assets/Salesforce-Logo.png';
import {
  Container,
  Card,
  Input,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button
} from "reactstrap";
//import ReactMarkdown from 'react-markdown/with-html';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import WorkrailsInterpreter from '@workrails/workrails-interpreter';
import Select from 'react-select';
//import WorkrailsInterpreter from './WorkrailsInterpreter';
const jsforce = require('jsforce');
const dateFormats = {flatPickr: 'n/j/Y', formValue: 'M/D/YYYY'};

let conn;
const sfData = {};

function filter(text){
  let val = text;
  if(!val && typeof(val) === 'string'){
    return undefined;
  }
  if(!val || typeof(val) !== 'string'){
    return text;
  }
  if(val[0] === "\""){
    val = JSON.parse(val);
  }
  if(val === ""){
    return undefined;
  }
  return val;
}

class ProductDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "main",
      tabs: [],
      salesforce: {
        closeDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 7),
        revenuedept: [],
        edition: [],
      },
      sfOverrides: {},
      hasSubmitted: false,
      missingFields: [],
      loading: false,
      hasOpened: false,
      productRows: "",
      dropdownOpen: false,
    };
    this.isDropdownOptionDisabled = this.isDropdownOptionDisabled.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.sfHandleChange = this.sfHandleChange.bind(this);
    this.onSfSubmit = this.onSfSubmit.bind(this);
  }


  onSfSubmit(){
    this.setState({hasSubmitted: true});
    if(!this.areFieldsValid()){
      return;
    }else{
      this.setState({hasSubmitted: false});
      this.setState({sfLoading: true});
    }
    const log_transaction = this.props.log_transaction;
    const handleError = this.handleError.bind(this);
    const obj = this.salesforceConfig().objects;
    const history = [];
    let objIndex = 0;
    const items = this.props.selectedProducts.map(prod => prod.id);
    const accountName =  this.props.sfAccount.label;
    const totalValue = this.props.totalCost * 100;
    const proposalLazyCreate = this.props.proposalLazyCreate;
    const create = () => {
      const opp = {};
      const fields = this.salesforceConfig().fields;
      fields.filter((field) => field.obj === obj[objIndex].name).forEach((field) => {
        if(!field.dont_send){
          if(this.state.salesforce[field.name]=== null){
            return;
          }
          if(field.type === 'date'){
            opp[field.api_name ? field.api_name : field.name] = field.override ? field.override : moment(this.state.salesforce[field.name]).format('YYYY-MM-DD');
          }else if(field.type === 'picklist' || field.type === 'lookup'){
            opp[field.api_name ? field.api_name : field.name] = field.override ? field.override : this.state.salesforce[field.name].value;
          }else if(field.type === 'number'){
            opp[field.api_name ? field.api_name : field.name] = field.override ? field.override : parseInt(this.state.salesforce[field.name], 10);
          }else{
            opp[field.api_name ? field.api_name : field.name] = field.override ? field.override : this.state.salesforce[field.name];
          }
        }
      });
      if(obj[objIndex].rels){
        obj[objIndex].rels.forEach((rel) => {
          opp[rel.field] = history.find((hist) => hist.name === rel.obj).id;
        });
      }
      console.log(opp);
      if(objIndex === 1){
          const proposal = {
            userId: '3eceb28a-c5e8-49b6-97e1-44c4696973ab',
            companyId: "1223320a-9929-4aa0-a419-01640fa9886e",
            parentId: "93631832-beba-49d0-802c-5ad5fd54f531",
            parentType: 'catalog',
            status: 'draft',
            type: 'proposal',
            settings: {
              issueDate: moment().toISOString(),
            },
          };
          proposal.name = opp["Name"];
          proposal.settings = JSON.stringify(_.assign(proposal.settings, {
            value: totalValue,
            clientName:accountName,
            expiryDate: moment(opp['CloseDate']).toISOString(),
            email: localStorage.getItem('sf_email'),
            items: items,
            options: [fields ],
          }));

          proposalLazyCreate(proposal);
      }
      try{
        conn.sobject(obj[objIndex].type).create(opp, function(err, ret) {
          if(err){
            return handleError(err.toString());
          }
          history.push({ name: obj[objIndex].name, id: ret.id});
          if(obj.length > objIndex + 1){
            objIndex++;
            create();
          }else{
            log_transaction("clicked", "Create Opportunity", ret.id, opp.Name);
            handleError(true, history[0].id);
          }
        });
      }catch(e){
      }
    };
    create();
  }

  areFieldsValid(){
    const fields = this.salesforceConfig().fields;
    for(const ifield in fields){
      const field = fields[ifield];
      if(field.override === undefined){
        if(!field.can_be_null && (this.state.salesforce[field.name] === undefined || (this.state.salesforce[field.name].trim && this.state.salesforce[field.name].trim() === ""))){
          return false;
        }
      }
    }
    return true;
  }

  salesforceConfig = () => {
    const nameText = (this.props.selectedProducts && this.props.selectedProducts.length > 0) ? this.props.selectedProducts[0].name + this.props.selectedProducts.slice(1).reduce((total, prod) => total = total + ', ' + prod.name, '') : '';
    const fullName = ((this.props.sfAccount ? this.props.sfAccount.label : "") + ' - ' + nameText + ' - ' + moment(this.state.salesforce["Close Date"]).format(dateFormats.formValue));
    return {
    objects: [
      {
        name: "opp",
        type: "Opportunity",
      },
    ].concat(this.props.selectedProducts.map((prod) => {
      return {
        name: prod.name,
        type: "OpportunityLineItem",
        rels: [
          {
            field: 'OpportunityId',
            obj: 'opp'
          }
        ],
      }
    })),
    fields: [
      {
        name: 'Opportunity Name',
        obj: 'opp',
        api_name: 'Name',
        type: 'text',
        can_override: fullName.length < 120 ? fullName : fullName.substring(0, 120),
        max_length: 120,
      },
      {
        name: 'Close Date',
        obj: 'opp',
        api_name: 'CloseDate',
        type: 'date',
        default: new Date(),
      },
      {
        name: 'Amount',
        obj: 'opp',
        type: 'number',
        override: this.props.totalCost,
      },
      {
        name: 'RecordTypeId',
        obj: 'opp',
        type: 'text',
        hidden: true,
        default: '0120t0000004XpyAAU'
      },
      {
        name: 'Pricebook2Id',
        obj: 'opp',
        type: 'text',
        hidden: true,
        default: '01s44000000DljQAAS'
      },
      {
        name: 'Account Name',
        obj: 'opp',
        api_name: 'AccountId__r.Name',
        type: 'text',
        dont_send: true,
        override: this.props.sfAccount && this.props.sfAccount.label,
      },
      {
        name: 'AccountId',
        obj: 'opp',
        type: 'text',
        hidden: true,
        override: this.props.sfAccount && this.props.sfAccount.value,
      },
      {
        name: 'RecordTypeId',
        obj: 'opp',
        type: 'text',
        hidden: true,
        default: ((window.location.href.includes('test')) && window.location.href.indexOf('test') < window.location.href.indexOf('.')) ? '0120t0000004Xpy' : '0122R0000006Guz',
      },
      {
        name: 'Stage',
        obj: 'opp',
        api_name: 'StageName',
        type: 'picklist',
        picklist: 'StageName',
        default: 'Contract Requested',
      },
      {
        name: 'Type',
        type: 'text',
        obj: 'opp',
        hidden: true,
        default: 'Existing Business',
      },
      {
        name: 'Service_Add_On__c',
        obj: 'opp',
        type: 'boolean',
        default: true,
        hidden: true,
      },
      {
        name: 'Primary Contact',
        api_name: 'Primary_Contact__c',
        obj: 'opp',
        type: 'lookup',
        query: { AccountId: this.props.sfAccount && this.props.sfAccount.value },
        lookup: 'Contact',
      },
      {
        name: 'Client Administrator',
        api_name: 'Client_Administrator__c',
        obj: 'opp',
        type: 'lookup',
        query: { AccountId: this.props.sfAccount && this.props.sfAccount.value },
        lookup: 'Contact',
        can_override: this.state.salesforce['Primary Contact']
      },
      {
        name: 'Billing Contact',
        api_name: 'Client_Accounts_Payable__c',
        obj: 'opp',
        type: 'lookup',
        query: { AccountId: this.props.sfAccount && this.props.sfAccount.value },
        lookup: 'Contact',
        can_override: this.state.salesforce['Primary Contact']
      },
      {
        name: 'Signatory',
        api_name: 'Signatory__c',
        obj: 'opp',
        type: 'lookup',
        query: { AccountId: this.props.sfAccount && this.props.sfAccount.value },
        lookup: 'Contact',
        can_override: this.state.salesforce['Primary Contact']
      },
    ].concat(this.props.selectedProducts.length > 0 ? this.props.selectedProducts.reduce((total, prod) => {
      return total.concat([
        {
          name: 'Quantity',
          obj: prod.name,
          type: 'number',
          override: filter(this.props.selectedProductOptions[prod.rows.find((row) => row.label === "Quantity").id]) || 1,
          hidden: true,
        },
        {
          name: 'UnitPrice',
          obj: prod.name,
          type: 'number',
          override: JSON.parse(prod.rows.find((row) => row.row_type === "component:pricing").control).schedule.cost / 100,
          hidden: true,
        },
        {
          name: 'Discount',
          obj: prod.name,
          type: 'number',
          override: filter(this.props.selectedProductOptions[prod.rows.find((row) => row.label === "Sales Price").id]) !== undefined ? ((1 - filter(this.props.selectedProductOptions[prod.rows.find((row) => row.label === "Sales Price").id]) / (JSON.parse(prod.rows.find((row) => row.row_type === "component:pricing").control).schedule.cost / 100)) * 100) : 0,
          hidden: true,
        },
        {
          name: 'Zuora_Rate_Plan_Charge_ID__c',
          obj: prod.name,
          type: 'text',
          override: filter(prod.rows.find((row) => row.label === "Zuora Rate Plan Charge ID").control) || "",
          hidden: true,
        },
        {
          name: 'SKU__c',
          obj: prod.name,
          type: 'text',
          override: filter(prod.rows.find((row) => row.label === "SKU#").control) || "",
          hidden: true,
        },
        {
          name: 'Name',
          dont_send: true,
          obj: prod.name,
          type: 'text',
          override: filter(prod.name) || "",
          hidden: true,
        },
        {
          name: 'Zuora_Product_ID__c',
          obj: prod.name,
          type: 'text',
          override: filter(prod.rows.find((row) => row.label === "Zuora Product Id").control) || "",
          hidden: true,
        },
        {
          name: "Zuora_Product_Rate_Plan_ID__c",
          obj: prod.name,
          type: 'text',
          override: filter(prod.rows.find((row) => row.label === "Zuora Rate Plan Id").control) || "",
          hidden: true,
        },
        {
          name: "Product2Id",
          obj: prod.name,
          type: 'text',
          override: filter(prod.rows.find((row) => row.label === "Salesforce product 2 Id").control) || "",
          hidden: true,
        },
        {
          name: "PricebookEntryId",
          can_be_null: true,
          obj: prod.name,
          type: 'text',
          override: filter(prod.rows.find((row) => row.label === "PricebookEntryId").control) || "",
          hidden: true,
        },
      ]);
    }, []) : [])
  }}

  onDescribe(err, meta) {
    const fields = this.salesforceConfig().fields;
    const { salesforce }  = this.state;
    fields.filter((field) => field.type === 'picklist').forEach((field) => {
      sfData[field.picklist] = meta.fields.find((sfield) => sfield.name === field.picklist).picklistValues.filter((pick) => pick.active === true);
    });
    fields.forEach((field) => {
      if(field.type === 'picklist'){
        salesforce[field.name]= sfData[field.picklist].find((pick) => pick.label === field.default);
      }else{
        salesforce[field.name] =  field.default;
      }
    });
  }

  receiveRecords(type, err, rec){
    if(err){ return console.log(err) }
    this.setState({[type + '-data']: rec});
    this.setState({[type + '-loading']: false});
  }

  onModalToggle(){
    if(this.state.finalMsg){
      this.setState({finalMsg: undefined});
      this.setState({sfLoading: false}, () => { this.props.toggleSfModal()} );
    }
      const { salesforce } = this.state;
    const fields = this.salesforceConfig().fields;
    fields.filter((field) => field.can_override).forEach((field) => {
          salesforce[field.name] = field.can_override;
      });
    fields.filter((field) => field.override).forEach((field) => {
          salesforce[field.name] = field.override;
      });
    this.setState({salesforce});
    fields.filter((field) => field.type === 'lookup').forEach((field) => {
      if(!field.big){
        conn.sobject(field.lookup)
          .find(
            field.query || {},
            { Id: 1,
              Name: 1,
              }
          )
          .execute(this.receiveRecords.bind(this, field.name));
      }
    });
  }


  componentDidMount() {
    if(this.props.isSalesforce){
      this.props.setSfModalListener(this.onModalToggle.bind(this));
      conn = new jsforce.Connection({ accessToken: localStorage.getItem('sf_session') });
      conn.sobject("Opportunity").describe(this.onDescribe.bind(this));
    }
    this.props.setPageDetail(this.props.data);
    this.props.log_transaction("viewed", "product", this.props.data.id, this.props.data.name);
    window.scrollTo(0, 0);

    this.props.setSowBuilderPreviewHeight();
    window.addEventListener("resize", this.props.setSowBuilderPreviewHeight);
    /*if (!this.props.isSowBuilderPreviewShowing()) {
      this.props.toggleSowBuilderPreview();
    }*/
  }

  sfCreateOpportunity(opp){
  }


  componentWillUnmount() {
    window.removeEventListener("resize", this.props.setSowBuilderPreviewHeight);

    if (this.props.isSowBuilderPreviewShowing()) {
      this.props.toggleSowBuilderPreview();
    }
  }

  sfHandleChange(key, value){
    const { sfOverrides } = this.state;
    if(!sfOverrides[key]){
      sfOverrides[key] = true;
    }
    const salesforce = this.state.salesforce;
    salesforce[key] = value;
    this.setState({salesforce}, () => {
      const salesforce = this.state.salesforce;
      this.salesforceConfig().fields.filter((field) => field.can_override).forEach((field) => {
        if(!sfOverrides[field.name]){
          salesforce[field.name] = field.can_override;
        }
      });
      this.setState({salesforce});
    });
    this.setState({sfOverrides});
  }

  sfHandleSelectChange(e, val){
    const salesforce = this.state.salesforce;
    if(val === "region"){
      salesforce.reqresource = e;
    }
    salesforce[val] = e;
    this.setState({salesforce});
  }

  toggleDropdown() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }



  getQuantityCheckRows() {
    return this.props.data && this.props.data.rows.find(row => row.row_type === 'checkbox' && row.label.toLowerCase().startsWith('add-on'));
  }

  isDropdownOptionDisabled(row, option) {
    if (row.row_type === "dropdown" && row.label.toLowerCase().includes('hour')) {
      if(option.label.toLowerCase() === '2 hours' || option.label.toLowerCase() === '4 hours') {
        const quarterRow = this.props.data.rows.find(r => r.row_type === "dropdown" && r.label.toLowerCase().includes('quarter'));
        if(quarterRow) {
          const selectedQuarter = this.props.selectedProductOptions[quarterRow.id]
          if(selectedQuarter && selectedQuarter.value.quarter === 1) {
            return true;
          }
        }
      }
    }

    return false;
  }
  sfHandleAccountInputChange(field, e, opts){
    if(opts.action === 'input-change'){
      this.setState({[field.name + '-input']: e});
      if(e.length >= 3){
        this.setState({[field.name + '-loading']: true});
        conn.sobject(field.lookup)
          .find(
            { Name : { $like : '%' + e + '%' } },
            { Id: 1,
              Name: 1,
              }
          )
          .execute(this.receiveRecords.bind(this, field.name));
      }
    }
  }

  getField(field){
    if(field.hidden === true){
      return null;
    }
    switch(field.type){
      case "number":
      case 'text':
        return (
          <div  className={!field.override && this.state.hasSubmitted && (this.state.salesforce[field.name] === undefined || (this.state.salesforce[field.name].trim && this.state.salesforce[field.name].trim()) === "") ? 'invalid input' : 'input'}>
            <label>{field.name}</label>
            <Input maxlength = {field.max_length ? field.max_length : 9999} type={field.type} placeholder={field.name} disabled={filter(field.override) !== undefined} value={filter(field.override) !== undefined ? field.override : this.state.salesforce[field.name]} onChange={(e) => this.sfHandleChange(field.name, e.target.value)} />
          </div>
        );
      case 'date':
        return (
          <div  className={!field.override && this.state.hasSubmitted && (this.state.salesforce[field.name] === undefined || (this.state.salesforce[field.name].trim && this.state.salesforce[field.name].trim()) === "") ? 'invalid input' : 'input'}>
            <label>{field.name}</label>
            <Flatpickr
              className="flat form-control-m form-control"
              readonly={false}
              id={field.name}
              value={this.state.salesforce[field.name] ? this.state.salesforce[field.name] : field.default}
              options={{dateFormat: dateFormats.flatPickr}}
              onChange={(date) => this.sfHandleChange(field.name, date[0])}
             />
          </div>
        );
      case 'lookup':
        if(field.big){
          return(
          <div  className={!field.override && this.state.hasSubmitted && (this.state.salesforce[field.name] === undefined || (this.state.salesforce[field.name].trim && this.state.salesforce[field.name].trim()) === "") ? 'invalid input' : 'input'}>
            <label>{field.name}</label>
            <Select clearable={true} noOptionsMessage={() => this.state[field.name + '-input'] && this.state[field.name + '-input'].length >3 ? this.state[field.name+ '-loading'] ? 'Loading...' : 'None match your query.' : 'Enter 3 or more characters.'} style={{width: '150px'}} value={this.state.salesforce[field.name]} onInputChange={(e, opts) => this.sfHandleAccountInputChange(field, e, opts)} inputValue={this.state[field.name + '-input']} onChange={(e) => { this.setState({[field.name + '-input']: undefined}); this.sfHandleChange(field.name, e); }}
                options= {(this.state[field.name + '-data'] || []).map(function(account) {
                  return {label:account.Name, value:account.Id}
                })} />
            </div>
          );
        }else{
          const ops = (this.state[field.name + '-data'] || []).map(function(account) {
                  return {label:account.Name, value:account.Id}
                });
          return (
            <div  className={!field.override && this.state.hasSubmitted && (this.state.salesforce[field.name] === undefined || (this.state.salesforce[field.name].trim && this.state.salesforce[field.name].trim()) === "") ? 'invalid input' : 'input'}>
              <label>{field.name}</label>
              <Select value={this.state.salesforce[field.name]} onChange={(e) => this.sfHandleChange(field.name, e)}
                options= {ops} />
            </div>
          );
        }
      //eslint-disable-next-line
      case 'picklist':
        return (
          <div  className={!field.override && this.state.hasSubmitted && (this.state.salesforce[field.name] === undefined || (this.state.salesforce[field.name].trim && this.state.salesforce[field.name].trim()) === "") ? 'invalid input' : 'input'}>
            <label>{field.name}</label>
            <Select value={this.state.salesforce[field.name]} options={sfData[field[field.type]] || this.state[field.name + '-data']} onChange={(e) => this.sfHandleChange(field.name, e)} />
          </div>
        );
      default:
        return <label>Bad Field Type</label>
    }
  }

  handleError(msg, oppId){
    this.props.toggleSfModal();
    this.setState({sfLoading: false});
    this.setState({oppId});
    this.setState({finalMsg: msg});
  }

  getModal(){
    if(this.state.finalMsg === true){
      return <Alert color="success">Opportunity Successfully Created! <a target="_blank" rel="noopener noreferrer" href={'https://' + window.location.hostname + '/' + this.state.oppId}>View In Salesforce</a></Alert>
    }else if(this.state.finalMsg){
      return <Alert color="danger">{this.state.finalMsg}</Alert>
    }else if(this.state.sfLoading){
      return <Alert color="primary">Creating Opportunity...</Alert>
    }
    const inputs = this.salesforceConfig().fields.filter((field) => !field.hidden);
    return (
        <Modal className="opportunity" isOpen={this.props.isSfModalOpen} toggle={this.props.toggleSfModal}>
          <ModalHeader toggle={this.props.toggleSfModal}>
            <img src={'https://veeva.workrails.com' + sfLogo} alt='logo_not_found' />
            Creating Opportunity <span style={{color:"blue"}}>{this.props.sowBuilderOppName}</span> for Account: <span style={{color:"purple"}}>{this.props.sfAccount && this.props.sfAccount.label}</span>
          </ModalHeader>
          <ModalBody>
            <div className='inputOpp'>
              <div className='left'>
                {inputs.slice(0, (inputs.length / 2) + 1).map((field) => {
                  return this.getField(field);
                })}
              </div>
              <div className='right'>
                {inputs.slice((inputs.length / 2) + 1).map((field) => {
                  return this.getField(field);
                })}
              </div>
            </div>
            <Button className='submit' onClick={this.onSfSubmit}>Submit</Button>
          </ModalBody>
          <ModalFooter>
            <div className="poweredBy">Powered by <a href="https://www.workrails.com" target="_blank" rel="noopener noreferrer">WorkRails</a></div>
          </ModalFooter>
        </Modal>
    );
  }

  render() {
    const subtitle = this.props.renderProductPriceTime(this.props.data);
    return (
      <Container className="detail">
        {this.props.isSalesforce && this.getModal()}
          <Link to="/"
                onClick={(e) => {this.props.backToPage(e); this.props.log_transaction('viewed', 'page', 'home'); }}
                color="link"
                className="backButton"
          >
            <FontAwesomeIcon icon="long-arrow-alt-left" />
            Back
          </Link>
        {this.props.data && this.props.data.description ? (
          <Card onClick={this.handleSelect} className={`${this.props.isSowBuilderPreviewShowing() ? "preview-showing" : ""} ${!this.props.isSowBuilderEmpty() ? "has-products" : ""}`}>
            <CardHeader>
              <CardTitle>{this.props.data.name}</CardTitle>
              {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
              <div className="cta">
                {this.props.isSowBuilderAdded(this.props.data) ? (
                  <button
                    className="added"
                    onClick={() =>
                      this.props.handleSowBuilderRemove(this.props.data)
                    }
                  >
                    Added to Order
                  </button>
                ) :
                    <button className="add" onClick={() => this.props.handleSowBuilderAdd(this.props.data) }>Add to Order</button>
                    /*this.props.isSowBuilderLimit()
                      ? <Popup trigger={<button className="disabled">Add to SOW</button>} inverted content='You have already added 3 items to your SOW.' />
                      : <button className="add" onClick={() => this.props.handleSowBuilderAdd(this.props.data) }>Add to SOW</button>*/
                }
              </div>
            </CardHeader>
            <CardBody style={{ overflow: "auto" }}>
              <WorkrailsInterpreter
                  selectedProductOptions={this.props.selectedProductOptions}
                  getProductOptionState={this.props.getProductOptionState}
                  handleProductOptionSelection={this.props.handleProductOptionSelection}
                  isSowBuilderLimit={this.props.isSowBuilderLimit}
                  data={this.props.data} />
            </CardBody>
          </Card>
        ) : (
          <h1>Something went wrong.</h1>
        )}
        {this.props.getSowBuilderSummary(this.props.data)}
      </Container>
    );
  }
}

export default ProductDetail;
