import React, { Component } from "react";
import { connect } from "react-refetch";
import Constants from "./Constants.js";
import _ from "lodash";
import Site from "./components/Site.js";
//import ldata from './catalog-prototype.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import 'semantic-ui-css/semantic.min.css';
import { Tools } from '@workrails/workrails-interpreter';
import moment from 'moment';
library.add(fas);
var toBuffer = require('blob-to-buffer')


const getUser = function() {
  return isSalesforce() ? { id: localStorage.getItem('sf_userid'), email: localStorage.getItem('sf_email') } : {};
};

Tools.configure({
  isProd: true,
  getUser: (() => {return getUser() }),
  getCatalog: (() => { return Constants.CATALOG_ID }),
  companyId: '1223320a-9929-4aa0-a419-01640fa9886e',
  debug: false,
});

const sfData = {};

const IS_SALESFORCE = !!localStorage.getItem('sf_session')
const isSalesforce = function(){
  return window.location.href.includes('force') && window.location.href.indexOf('.com') > window.location.href.indexOf('force');
};

/*const getUserAuth = function(user) {
  if(!user && !getUser()){
    return {'Content-type':'application/json', 'Authorization':Constants.AUTH};
  }

  return {
    'Content-type': 'application/json',
    'Authorization': (user||getUser()).authorization
  };
};*/

let conn;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
      loading: true,
      dimmed: false,
      data: {},
      geo: {}
    };
    this.log_transaction = this.log_transaction.bind(this);
    this.clearUser = this.clearUser.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.isDimmed = this.isDimmed.bind(this);
  }

  sfCallback(err, res, value){
    if(err){
      console.log(err);
      return;
    }
    sfData[value] = res.records;
  }

  sfCreateOpportunity(opp, doc, pdf, docName, staffing, order, callback){
    const log_transaction = this.log_transaction;
    try{
      conn.sobject("Opportunity").create(opp, function(err, ret){
        if(err){
          return callback(err.toString());
        }
        log_transaction("clicked", "Create Opportunity", ret.id, opp.Name);
        staffing.Opportunity__c = ret.id;
        order.Opportunity__c = ret.id;
        toBuffer(doc, function (err, buffer) {
          if (err) { return callback(err.toString()); }
          var base64data = new Buffer(buffer).toString('base64');
          conn.sobject('Attachment').create({
                  ParentId: ret.id,
                  Name : docName + ".docx",
                  Body: base64data,
                  ContentType : "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              },
              function(err, uploadedAttachment) {
          if (err) { return callback(err.toString()); }
            conn.sobject("Services_Products__c").create(staffing, function(err, ret){
              if(err){
                console.log(err);
                return callback(err.toString());
              }

            });
          });
         })
      });
    }catch(e){
    }
  }

  isLoggedIn() {
    return this.state.user.userId !== undefined;
  }

  clearUser() {
    this.setState({ user: {} });
    localStorage.removeItem("user/1i9");
    if (this.props.signinRequestReset) this.props.signinRequestReset();
  }


  componentDidMount() {
    this.setState({ user: getUser() });
    this.setState({ startTime: new Date().getTime() });
    if(window.location.pathname === '/'){
     this.log_transaction("viewed", "page", "home");
    }
  }

  componentDidUpdate(prevProps) {
    if (
      _.get(prevProps, "catalogFetch.pending") &&
      !_.get(this.props, "catalogFetch.pending")
    ) {
      const data = _.get(this.props, "catalogFetch.value.data");
      console.log(data);
      this.setState({ loading: false, initialized: true });
      document.title = data.name;
      if (
        _.get(this.props, "locationFetch.pending") === false ||
        new Date().getTime() - this.state.startTime > 5000
      ) {
      }
    }
    if (
      _.get(prevProps, "signInFetch.pending") &&
      !_.get(this.props, "signInFetch.pending")
    ) {
      if (_.get(this.props, "signInFetch.rejected") === false) {
        const user = _.get(this.props, "signInFetch.value.data");
        this.setState({ user });
        localStorage.setItem("user/1i9", JSON.stringify(user));
      }
    }
    if (_.get(prevProps, 'proposalsFetch.pending') && !_.get(this.props, 'proposalsFetch.pending')) {
      this.isLoading(false);
    }
  }

  getSfData(){
    return sfData;
  }

  getUser() {
    if (getUser()) {
      return getUser();
    }
    const user = {};
    user.email = "guest@generic.com";
    user.id = "cheese";
    return user;
  }

  isLoading(loading) {
    if (loading !== undefined) {
      this.setState({ loading: loading });
      return;
    }
    return this.state.loading;
  }

  renderLoading() {
    if (this.state.loading) {
      return (
          <FontAwesomeIcon icon="spinner" size="3x" spin className="loading"/>
      )
    }
  }

  isDimmed(dimmed) {
    if (typeof dimmed === "undefined") {
      return this.state.dimmed;
    }
    this.setState({ dimmed });
  }

  renderDimmed() {
    if (!this.state.dimmed) return null;

    return <div className="dimmed" />;
  }

  log_transaction(action, type, id, hname) {
    Tools.logTransaction(action, type, id, hname);
  }

  render() {
    if (!IS_SALESFORCE) {
      return null
    }
    if(!this.state.initialized) {
      return this.renderLoading();
    }
    return (
      <div className="App">
        <Site
          sfCreateOpportunity={this.sfCreateOpportunity}
          getSfData={this.getSfData}
          isSalesforce = {isSalesforce()}
          clearUser={this.clearUser}
          productRowsFetch={this.props.productRowsFetch}
          data={this.props.catalogFetch.value.data}
          productRowsLazyFetch={this.props.productRowsLazyFetch}
          proposalLazyCreate={this.props.proposalLazyCreate}
          proposalCreate={this.props.proposalCreate}
          proposalLazyUpdate={this.props.proposalLazyUpdate}
          proposalUpdate={this.props.proposalUpdate}
          proposalLazyDelete={this.props.proposalLazyDelete}
          proposalDelete={this.props.proposalDelete}
          proposalsLazyFetch={this.props.proposalsLazyFetch}
          proposalsFetch={this.props.proposalsFetch}
          proposalQuoteLazyCreate={this.props.proposalQuoteLazyCreate}
          proposalQuoteCreate={this.props.proposalQuoteCreate}
          proposalQuotesFetch={this.props.proposalQuotesFetch}
          log_transaction={this.log_transaction}
          lazySignIn={this.props.lazySignIn}
          signInFetch={this.props.signInFetch}
          isLoggedIn={this.isLoggedIn}
          isLoading={this.isLoading}
          isDimmed={this.isDimmed}
          user={this.state.user ? this.state.user.user : null}
        />
        {this.renderLoading()}
        {this.renderDimmed()}
      </div>
    );
  }
}

export default connect(props => {
  return {
    catalogFetch: {
      url: Constants.API_BASE + "/catalogs/" + Constants.CATALOG_ID,
      method: "GET",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        token: Constants.AUTH
      }
    },
    productRowsLazyFetch: productId => ({
      productRowsFetch: {
        url: `${Constants.APP_BASE}/api/v1/product-rows/product-id/${productId}`,
        headers: {
          Authorization: "ccc10ad7-b5aa-4c1d-8cbb-b4c3737efc8d"
        },
        force: true
      }
    }),
    lazyLogTransaction: transaction => ({
      logTransactionFetch: {
        url: `${Constants.API_BASE}/transactions/create`,
        method: "POST",
        headers: {
          token: "88c56158-1a6d-4e90-8834-d5c22aafb3f9",
          "Content-Type": "application/json"
        },
        body: `{"data": ${JSON.stringify(transaction)}}`
      }
    }),
    lazySignIn: payload => ({
      signInFetch: {
        url: `${Constants.API_BASE}/users/auth`,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: `${JSON.stringify(payload)}`
      },
      signinRequestReset: () => ({
        signinRequest: {
          value: null
        }
      })
    }),
    proposalsLazyFetch: () => ({
      proposalsFetch: {
        url: Constants.APP_BASE + `/api/v1/documents/search?type=proposal&userId=${getUser().user.id}&companyId=${Constants.COMPANY_ID}`,
        method: 'GET',
        force: true,
        headers: {
          Authorization: getUser().authorization
        },
        andThen: results => {
          return {
            proposalQuotesFetch: {
              url: Constants.APP_BASE + `/api/v1/attachments/attachable-id-list?internalType=proposal`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              force: true,
              body: `{"data":[${results.data.map(p => '"' + p.id + '"').join(',')}]}`,
            }
          };
        }
      }
    }),
    proposalLazyCreate: (proposal) => ({
      proposalCreate: {
        url: Constants.APP_BASE + "/api/v1/documents/create",
        method: 'POST',
        force: true,
        headers: {
          token: Constants.AUTH,
        },
        body: `${JSON.stringify({data: proposal})}`,
      }
    }),
    proposalLazyUpdate: (proposal) => ({
      proposalUpdate: {
        url: Constants.APP_BASE + `/api/v1/documents/document-id/${proposal.id}/update`,
        method: 'PUT',
        force: true,
        headers: {
          Authorization: getUser().authorization
        },
        body: `${JSON.stringify({data: proposal})}`,
      }
    }),
    proposalLazyDelete: (proposalId) => ({
      proposalDelete: {
        url: Constants.APP_BASE + `/api/v1/documents/document-id/${proposalId}/delete`,
        method: 'DELETE',
        force: true,
        headers: {
          Authorization: getUser().authorization
        },
      }
    }),
    proposalQuoteLazyCreate: (proposal, data) => ({
      proposalQuoteCreate: {
        url: Constants.APP_BASE + `/api/v1/attachments/attachable-id/${proposal.id}?internalType=proposal`,
        method: 'POST',
        force: true,
        headers: {
          'Content-Type': null,
          Authorization: getUser().authorization
        },
        body: data,
      }
    }),
  };
})(App);
