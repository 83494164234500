//2938a723-4124-4139-978d-2900237d9499
//eef3b7f0-2941-4819-bc4f-1e13fa32638b
const Constants = {
  API_BASE: process.env.REACT_APP_API_BASE,
  APP_BASE: process.env.REACT_APP_APP_BASE,
  CATALOG_ID: ((window.location.href.includes('test')) && window.location.href.indexOf('test') < window.location.href.indexOf('.')) ? "93631832-beba-49d0-802c-5ad5fd54f531" : "884197af-1ebb-4784-9ccc-82d961165bf8",
  COMPANY_ID: "1223320a-9929-4aa0-a419-01640fa9886e",
  AUTH: process.env.REACT_APP_WR_TOKEN
};
//AUTH: 'eef3b7f0-2941-4819-bc4f-1e13fa32638b', -- prod auth
//9f72d9a5-3516-4646-8800-7a55f46b2107
//88c56158-1a6d-4e90-8834-d5c22aafb3f9
export default Constants;
